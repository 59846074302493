'use client';
import React, { ReactNode } from 'react';
import { useFormControlContext } from './FormControl';

export type LabelProps = {
	className?: string;
	children?: ReactNode;
};

export default function Label(props: LabelProps) {
	const { className, children } = props;

	const formControl = useFormControlContext();

	return (
		<label className={`Label ${className ?? ''}`} htmlFor={formControl?.fieldId}>
			{children}
		</label>
	);
}
